.profile-pic-container__profile-pic {
  width: 23rem;
  pointer-events: none;
  border-radius: 1rem;
}

.profile-pic-container__profile-pic.scale-img {
  transform: scale(1.5);
}

.profile-pic-container {
  display: flex;
  place-items: center;
  place-content: center;
  height: 17rem;
  overflow: hidden;
  border-radius: 1rem 1rem 0 0;
}

.profile-pic-container svg {
  width: 80%;
  max-height: 14rem;
}

.profile-pic-container svg.white-bg {
  border-radius: 1rem;
  background-color: white;
}

@media only screen and (max-width: 767px) {
  .profile-pic-container {
    height: 0;
  }

  .profile-pic-container__profile-pic.profile {
    border-radius: 1rem 1rem 0rem 0rem;
  }

  .profile-pic-container__profile-pic {
    width: 100%;
    border-radius: 1rem;
  }
}